import { mixpanel } from "lib/mixpanel"
import mobileApp, { useMobileAppHelper } from "lib/mobileApp"
import { trpc } from "lib/trpc"
import posthog from "posthog-js"
import { useEffect } from "react"
import { setUser } from "@sentry/nextjs"
import router from "next/router"
import { facebookPixel } from "lib/fpixel"
import { tiktokPixel } from "lib/tiktokPixel"

let identifiedUser = false

export function useUser() {
  const {
    data: user,
    isLoading,
    isFetched,
  } = trpc.user.loadUser.useQuery(undefined, {
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  })

  const reportedSignUpToAnalyticsMutation =
    trpc.user.reportedSignUpToAnalytics.useMutation({
      onSuccess: () => {},
      onError: (error) => {
        console.error("Error reporting sign up to analytics", error)
      },
    })
  const trackFacebookLeadMutation =
    trpc.facebookConversionAPI.sendLead.useMutation()

  const setReferredBy = trpc.referFriends.setReferredBy.useMutation()

  const { isMobileApp } = mobileApp.mobileAppHelper()

  useEffect(() => {
    try {
      if (!isLoading && user && !identifiedUser) {
        const subscription = user.subscription
        const userDuplicate: any = { ...user }
        delete userDuplicate.subscription

        const postHogUserProperties = {
          ...userDuplicate,
          createdAt: user.createdAt.toISOString(),
          status: subscription?.status,
          maxNumberOfMessagesPerMonth:
            subscription?.maxNumberOfMessagesPerMonth,
        }

        mixpanel.identify(
          user.id,
          postHogUserProperties,
          isMobileApp
            ? {
                hasMobileAppInstalled: true, // register that the user has the mobile app installed
              }
            : undefined
        )
        mixpanel.people.set({
          $email: user.email,
          $name: user.name,
          $created: user.createdAt,
          ...userDuplicate,
          status: subscription?.status,
          maxNumberOfMessagesPerMonth:
            subscription?.maxNumberOfMessagesPerMonth,
        })

        tiktokPixel.identifyUser({
          email: user.email,
          phoneNumber: user.phoneNumber ?? undefined,
          externalId: user.id,
        })

        // Tell Sentry who's this user
        setUser({
          email: user.email ?? undefined,
        })

        posthog.setPersonPropertiesForFlags({
          createdAt: user.createdAt.toISOString(),
        })

        identifiedUser = true

        if (isMobileApp) {
          // Register that the user has the mobile app installed
          mixpanel.people.set_once({
            hasMobileAppInstalled: true,
          })
        }

        if (user.reportedSignUpToAnalytics == false) {
          const referralCode = localStorage.getItem("referralCode")

          mixpanel.track("Sign up", {
            provider:
              router.query.isGoogleSignup === "true"
                ? "Google"
                : "Email and Password",
            platform: isMobileApp ? "Mobile" : "Web",
            referredByAFriend: referralCode != null,
          })

          setTimeout(() => {
            try {
              // @ts-ignore
              window.tolt.signup(user.email)
            } catch (error: any) {
              console.error("Error calling tolt.signup", error)
            }
          }, 3000)

          facebookPixel.event("Lead")
          tiktokPixel.trackCompleteRegistration()
          trackFacebookLeadMutation.mutate()
          reportedSignUpToAnalyticsMutation.mutate()

          if (referralCode) {
            setReferredBy.mutate({
              referralCode: referralCode,
            })
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [isLoading, user])

  return { user, isLoading, isFetched }
}
